import {
  Alert,
  Button,
  Grid,
  Group,
  LoadingOverlay,
  MultiSelect,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import {
  IconAlertCircleFilled,
  IconClockExclamation,
} from "@tabler/icons-react";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import log from "loglevel";
import { useEffect, useMemo, useState } from "react";
import {
  Activity,
  ActivityStatus,
  DefaultBillingDetails,
  InsuranceBillingConfiguration,
  Integration,
  UpdateActivity,
  useMutationUpdateActivity,
  useQueryActivityTemplate,
  useQueryUsers,
} from "src/graphql";
import { useAuthContext } from "src/hooks";
import toast from "src/libs/toast";
import { AppointmentFields } from "src/pages/templates-activity/TemplateForm";
import {
  AppointmentDetailsSchema,
  unwrapAppointmentDetails,
} from "src/pages/templates-activity/utils";
import { activityStatusLabels } from "src/constants/activities";

import { SelectOption } from "src/types";
import { ySelectOptionSchema } from "src/utils";
import * as Yup from "yup";
import { FormikSelect, StyledLabel } from "../input";
import { FormikCheckbox } from "../input/FormikCheckbox";
import { FormikDateTimePickerInput } from "../input/FormikDateTimePickerInput";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../modal";
import { MemberNotes } from "../profile-details/MemberNotes";
import {
  BillingDetailsFields,
  BillingDetailsSchema,
  unwrapBillingDetails,
  wrapDefaultBillingDetails,
  wrapBillingDetails,
} from "./billing";

type EndActivityModalProps = {
  title: string;
  message?: string;
  activity: Activity | null;
  opened: boolean;
  onClose: () => void;
};

export const PostCompletionUpdateActivityModal = ({
  title,
  message,
  activity,
  opened,
  onClose,
}: EndActivityModalProps) => {
  const { selectedOrganization, userPerms } = useAuthContext();
  const isAtLeastAdmin = userPerms.atLeastOrgAdmin;
  const isActivityComplete = activity?.status === ActivityStatus.Complete;

  const [updateActivity, { loading: isLoading }] = useMutationUpdateActivity();

  const [selectedNotesMemberId, setSelectedNotesMemberId] = useState<
    string | null
  >(null);

  const { data: activityTemplateResponse, loading: activityTemplateLoading } =
    useQueryActivityTemplate(
      activity?.activityTemplateId ?? "",
      undefined,
      !activity?.activityTemplateId
    );

  const { data: usersResponse } = useQueryUsers({
    organizationId: selectedOrganization._id,
  });

  const defaultBillingDetails = useMemo(
    () =>
      activityTemplateResponse?.activityTemplate.data?.defaultBillingDetails ??
      null,
    [activityTemplateResponse?.activityTemplate.data]
  );

  const memberOptions = useMemo(
    () =>
      activity?.members.map((m) => ({
        label: m.memberDisplayName,
        value: m.memberId,
      })) ?? [],
    [activity]
  );

  const userOptions = useMemo(
    () =>
      usersResponse?.users.data?.map((u) => ({
        label: u.name,
        value: u._id,
      })) ?? [],
    [usersResponse?.users]
  );

  const initialValues = useMemo(() => {
    if (!activity) return;

    const canBeBillable =
      selectedOrganization.billingEnabled &&
      (activity.members.length === 1 ||
        activity.members.some(
          ({ primaryInsuranceCompanyId, secondaryInsuranceCompanyId }) =>
            !!primaryInsuranceCompanyId || !!secondaryInsuranceCompanyId
        ));

    return wrapFormValues({
      activity,
      canBeBillable,
      defaultBillingDetails: defaultBillingDetails,
      insuranceConfigurations:
        selectedOrganization.billingInsuranceConfigurations,
      users: userOptions,
    });
  }, [
    activity,
    defaultBillingDetails,
    selectedOrganization.billingEnabled,
    selectedOrganization.billingInsuranceConfigurations,
    userOptions,
  ]);

  useEffect(() => {
    if (!activity) return;
    setSelectedNotesMemberId(activity.members[0]?.memberId ?? null);
  }, [activity]);

  if (!activity || !initialValues) return null;

  const handleSubmit = async (values: EndActivityFormValues) => {
    try {
      const unwrappedFormValues = unwrapFormValues(values);

      // without explicitly defaulting to complete, the activity stays in progress
      const activityStatus =
        isAtLeastAdmin && isActivityComplete
          ? unwrappedFormValues.status // Only use the unwrapped form value if admin and activity is complete
          : ActivityStatus.Complete;

      const response = await updateActivity({
        variables: {
          input: {
            activityId: activity._id,
            updateActivity: {
              ...unwrappedFormValues,
              status: activityStatus,
            },
          },
        },
      });

      if (response.data?.updateActivity.success) {
        toast.success(message ?? "Updated activity");
        return onClose();
      }

      throw new Error(response.data?.updateActivity.message);
    } catch (error) {
      log.error(error);

      if (error instanceof Error) toast.error(error.message);
      else toast.error("Could not complete activity, please try again later");
    }
  };

  const showAppointmentInputs =
    selectedOrganization.activatedIntegrations?.includes(
      Integration.RedoxAppointmentScheduling
    );

  // NOTE: In single member mode, user can override insurance configurations
  // In multiple members mode, user can only configure claim details of the
  // insurance configurations of the members involved in the activity
  const isSingleMemberMode = activity.members.length === 1;

  // Disable billing toggle if there are no insurance details for the members
  const disableBillingToggle =
    !isSingleMemberMode &&
    !activity.billingDetails.length &&
    activity.members.every(
      (m) => !m.primaryInsuranceCompanyId && !m.secondaryInsuranceCompanyId
    );

  return (
    <Modal size={900} opened={opened} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        validationSchema={EndActivitySchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          submitForm,
          setFieldValue,
          values,
          isValid,
          isValidating,
        }) => (
          <Form onSubmit={handleSubmit}>
            <ModalHeader withSubHeader>{title}</ModalHeader>

            <ModalBody spacing="md">
              <Grid gutter="lg">
                <Grid.Col span={6}>
                  <Stack spacing={0} mb="xs">
                    <Title size={16}>Basic Details</Title>
                    <Text size="sm" color="dimmed">
                      Basic details of the activity.
                    </Text>
                  </Stack>

                  <Stack spacing={4}>
                    {isAtLeastAdmin && isActivityComplete && (
                      <FormikSelect
                        name="status"
                        label="Change Status"
                        options={[
                          {
                            label: "Scheduled",
                            value: ActivityStatus.Scheduled,
                          },
                          {
                            label: "In Progress",
                            value: ActivityStatus.InProgress,
                          },
                          {
                            label: "Complete",
                            value: ActivityStatus.Complete,
                          },
                        ]}
                        placeholder="Select status"
                      />
                    )}
                    <FormikDateTimePickerInput
                      name="startedAt"
                      label={
                        values.status.value !== ActivityStatus.Complete
                          ? "Start time"
                          : "Started at"
                      }
                      clearable={false}
                      required
                    />

                    <FormikDateTimePickerInput
                      name="completedAt"
                      label={
                        values.status.value !== ActivityStatus.Complete
                          ? "End time"
                          : "Completed at"
                      }
                      clearable={false}
                      required
                    />

                    {!isValidating &&
                      values.startedAt &&
                      values.completedAt &&
                      //Calculates if activity duration is > 2 hours
                      new Date(values.completedAt).getTime() -
                        new Date(values.startedAt).getTime() >
                        2 * 60 * 60 * 1000 && (
                        <Alert icon={<IconClockExclamation />} color="orange">
                          <Text size="sm">
                            Activity is longer than 2 hours, is this correct?
                          </Text>
                        </Alert>
                      )}

                    {/* Non form value, just to display which members are part of the activity */}
                    <Stack spacing={0}>
                      <StyledLabel>Members</StyledLabel>
                      <MultiSelect
                        required
                        name="members"
                        placeholder="Activity members"
                        disabled
                        data={memberOptions}
                        value={activity?.members.map((m) => m.memberId) ?? []}
                      />
                    </Stack>

                    <FormikSelect
                      name="user"
                      label="User"
                      placeholder="Select user"
                      required
                      options={userOptions}
                    />

                    {(selectedOrganization.billingEnabled ||
                      showAppointmentInputs) && (
                      <Stack spacing="xs">
                        {selectedOrganization.billingEnabled && (
                          <>
                            <FormikCheckbox
                              mt="sm"
                              name="billable"
                              label="Mark as billable"
                              disabled={disableBillingToggle}
                              onChangeOverride={(value) => {
                                if (!value) {
                                  setFieldValue("billingDetails", []);
                                  setFieldValue("billable", value);
                                  return;
                                }

                                const details =
                                  activity.billingDetails.length > 0
                                    ? wrapBillingDetails({
                                        details: activity.billingDetails,
                                        members: activity.members,
                                      })
                                    : wrapDefaultBillingDetails({
                                        defaults: defaultBillingDetails,
                                        members: activity.members,
                                        insurances:
                                          selectedOrganization.billingInsuranceConfigurations,
                                      });

                                setFieldValue("billingDetails", details);
                                setFieldValue("billable", value);
                              }}
                            />

                            {disableBillingToggle && (
                              <Alert
                                color="red"
                                icon={<IconAlertCircleFilled />}
                              >
                                <Text size="sm">
                                  Activity cannot be marked as billable as there
                                  are no insurance details for the member(s)
                                  involved.
                                </Text>
                              </Alert>
                            )}
                          </>
                        )}

                        {showAppointmentInputs && (
                          <FormikCheckbox
                            name="syncAppointment"
                            label="Sync appointment with EHR"
                          />
                        )}
                      </Stack>
                    )}
                  </Stack>
                </Grid.Col>

                {selectedNotesMemberId && (
                  <Grid.Col span={6}>
                    <Stack spacing={0}>
                      <Group>
                        <Text
                          size="sm"
                          color="dimmed"
                          maw={memberOptions.length > 1 ? 200 : undefined}
                        >
                          Add notes for the member(s) involved in the activity.{" "}
                          {memberOptions.length > 1 &&
                            "Select a member to view their notes."}
                        </Text>
                        {memberOptions.length > 1 && (
                          <Select
                            miw={150}
                            size="xs"
                            variant="filled"
                            data={memberOptions}
                            value={selectedNotesMemberId}
                            onChange={(id) => setSelectedNotesMemberId(id)}
                          />
                        )}
                      </Group>
                    </Stack>

                    <MemberNotes
                      noHorizontalPadding
                      multiMemberActivity={activity.members.length > 1}
                      key={selectedNotesMemberId}
                      activityId={activity._id}
                      memberId={selectedNotesMemberId}
                      showFilters={false}
                    />
                  </Grid.Col>
                )}

                {values.syncAppointment && (
                  <Grid.Col span={12}>
                    <Stack spacing={0} mb="sm">
                      <Title size={16}>Appointment Details</Title>
                      <Text size="sm" color="dimmed">
                        Details of the appointment synced with the EHR.
                      </Text>
                    </Stack>

                    <SimpleGrid cols={2} verticalSpacing={4}>
                      <AppointmentFields />
                    </SimpleGrid>
                  </Grid.Col>
                )}

                {values.billable && (
                  <Grid.Col span={12}>
                    <BillingDetailsFields
                      isSingleMemberMode={isSingleMemberMode}
                      fieldsPrefix="billingDetails"
                      setFieldValue={setFieldValue}
                      values={values.billingDetails}
                      members={activity.members}
                      defaults={defaultBillingDetails}
                    />
                  </Grid.Col>
                )}
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Group
                position={"right"}
                style={{
                  width: "auto",
                }}
              >
                <Button variant="outline" color="red" onClick={onClose}>
                  Cancel
                </Button>

                <Button
                  end-activity-button="true"
                  disabled={!isValid}
                  loading={isLoading}
                  onClick={() => submitForm()}
                  data-testid="end-activity-button-true"
                >
                  {title}
                </Button>
              </Group>
            </ModalFooter>
          </Form>
        )}
      </Formik>
      <LoadingOverlay visible={activityTemplateLoading} />
    </Modal>
  );
};

const EndActivitySchema = Yup.object({
  user: ySelectOptionSchema(Yup.string().required()).optional(),
  startedAt: Yup.date(),
  completedAt: Yup.date(),
  billable: Yup.boolean().optional(),
  billingDetails: Yup.array(BillingDetailsSchema.strict(true)).required(),
  status: ySelectOptionSchema(
    Yup.string().oneOf(Object.values(ActivityStatus)).required()
  ),
}).concat(AppointmentDetailsSchema);

type EndActivityFormValues = Yup.InferType<typeof EndActivitySchema>;

type WrapFormValuesOptions = {
  activity: Activity;
  canBeBillable: boolean;
  defaultBillingDetails: DefaultBillingDetails | null;
  insuranceConfigurations: InsuranceBillingConfiguration[];
  users: { label: string; value: string }[];
};

const wrapFormValues = ({
  activity,
  canBeBillable,
  defaultBillingDetails,
  insuranceConfigurations,
  users,
}: WrapFormValuesOptions): EndActivityFormValues => {
  const lastPing =
    activity.lastPing ??
    activity.scheduledEndAt ??
    dayjs(activity.date).subtract(activity.duration, "seconds").toISOString();

  // If activity is in progress, set status to complete
  const formStatus =
    activity.status === ActivityStatus.InProgress
      ? ActivityStatus.Complete
      : activity.status;

  const baseFormValues = {
    startedAt: dayjs(lastPing).subtract(activity.duration, "seconds").toDate(),
    completedAt: new Date(lastPing),
    billable: canBeBillable && activity.billable,
    syncAppointment: activity.syncAppointment,
    appointmentDetails: (activity.appointmentDetails ??
      undefined) as EndActivityFormValues["appointmentDetails"],
    user: (activity.userId && activity.userName
      ? { label: activity.userName, value: activity.userId }
      : users.find((u) => u.value === activity.userId)) as SelectOption<string>,

    status: {
      label: activityStatusLabels[formStatus],
      value: formStatus,
    },
  };

  if (!canBeBillable || !activity.billable)
    return {
      ...baseFormValues,
      billingDetails: [],
    };

  if (activity.billingDetails.length > 0)
    return {
      ...baseFormValues,
      billingDetails: wrapBillingDetails({
        details: activity.billingDetails,
        members: activity.members ?? [],
      }),
    };

  return {
    ...baseFormValues,
    billingDetails: wrapDefaultBillingDetails({
      defaults: defaultBillingDetails,
      members: activity.members ?? [],
      insurances: insuranceConfigurations,
    }),
  };
};

const unwrapFormValues = (values: EndActivityFormValues): UpdateActivity => {
  const completedAt = dayjs(values.completedAt).toDate();
  const duration = dayjs(completedAt).diff(values.startedAt, "seconds");
  const date = dayjs(values.startedAt).toISOString();

  return {
    date,
    userId: values.user.value,
    scheduledEndAt: completedAt.toISOString(),
    duration,
    lastPing: completedAt.toISOString(),
    billable: values.billable ?? false,
    billingDetails: unwrapBillingDetails(values.billingDetails),
    syncAppointment: values.syncAppointment,
    appointmentDetails: unwrapAppointmentDetails(values.appointmentDetails),
    status: values.status.value as ActivityStatus,
  };
};
